import { Box, Typography, styled } from '@mui/material';
import { Container } from '@/components/layout';
import { useResponsive } from '@/hooks/useResponsive';
import { CalendlyEmbeded } from '@/components/CalendlyEmbeded';

const HeaderWrapper = styled('div')`
  background-color: #d5ecec;
`;

export const HelpHeader = (): JSX.Element => {
  const isMobile = useResponsive('up', 'lg');
  return (
    <HeaderWrapper>
      <Container
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '12px',
          paddingTop: '12px',
        }}
      >
        <Box display="flex" alignItems="center" gap={5}>
          {isMobile ? (
            <Typography variant="body2" fontSize="18px" color="#0A444D">
              Need help?
            </Typography>
          ) : null}
          <Typography
            sx={{
              backgroundColor: '#EEF6F6',
              borderRadius: '8px',
              padding: '6px 16px',
            }}
            component="a"
            href="tel: +12024306797"
            variant="body2"
            fontSize={!isMobile ? '14px' : '18px'}
            color="#0A444D"
            fontWeight="700"
          >
            Call: +1 (202) 430-6797
          </Typography>
          {isMobile ? (
            <>
              <Typography variant="body2" fontSize="18px" color="#0A444D">
                or email us at
              </Typography>
              <Typography
                sx={{
                  backgroundColor: '#EEF6F6',
                  borderRadius: '8px',
                  padding: '6px 16px',
                }}
                component="a"
                href="mailto: support@onevillage.io"
                variant="body2"
                fontSize="18px"
                color="#0A444D"
                fontWeight="700"
              >
                support@onevillage.io
              </Typography>
              <Typography variant="body2" fontSize="18px" color="#0A444D">
                for assistance!
              </Typography>
            </>
          ) : null}
        </Box>
        {/* <CalendlyEmbeded
          sx={{ p: isMobile ? '11px 24px' : '8px', textTransform: 'none' }}
          color="primary"
        >
          Book a Demo
        </CalendlyEmbeded> */}
      </Container>
    </HeaderWrapper>
  );
};
